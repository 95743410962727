@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  font-family: 'Poppins', sans-serif;
}

a{
  text-decoration: none;
}
.navbar {
    z-index: 9999;
    background-color: transparent;
    position: sticky;
    top: 0;
    width: 100%;
    height: 70px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .nav {
    padding-right: 20px;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  h1 {
    margin: 0;
    font-size: 60px;
    letter-spacing: 10px;
    color: black;
    font-weight: 300;
    padding-left: 15px;
    width: 50%;
  }
  
  
  .nav-search::placeholder{
    color: black;
  }
  
  .nav-search:focus {
    outline: none;
  }
  
  .nav-items {
    padding-right: 20px;
  }
  
  .nav-items a {
    color: rgb(0, 0, 0);
    cursor: pointer;
    padding-right: 20px;
    text-decoration: none;
  }



.headertxt{
  color: white;
}
.section1{
  width: 100%;
  margin: 0;
  overflow: hidden;
  height: calc(100vh - 80px);
  display: grid;
  grid-template-columns: repeat(2 , 50%);
}

.intro-text{
  padding: 0 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 2rem;
  font-weight: 200;
}

.intro-img{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

.intro-img > img{
  width: 600px;
}

.intro-text > h2 > a{
  font-size: 20px;
  border: 1px solid black;
  border-radius: 15px;
  background-color: aqua;
  padding: 5px;
  cursor: pointer;
}
.intro-text > h2 > a:hover{
  transform: scale(1.1);
}

.Deploybtn{
  background: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.5rem;
  width: 180px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Deploybtn:hover{
  background: rgb(59, 59, 59);
}
