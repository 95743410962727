@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.error_msg{
  background-image: url('/public/gif.gif');
  background-color: black;
  height: 720px;
  background-position: center;
  background-repeat: no-repeat;
}

.error_msg h2{
  font-family: 'Poppins', sans-serif;
  color: white;
  margin-left: 5%;
}

.error_msg h1 a{
  text-decoration: underline;
  cursor: pointer;
}

/* .file_handler{
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
} */



/* .wallet-details button{
  height: 100%;
  width: 80px;
  background-color: aqua;
  border: 2px solid black;
  border-radius: 15px;
} */

.wallet-details .search_bar {
  text-align: center;
  width: 30%;
  height: 20%;
  padding: 8px;
  border-radius: 15px;
  background: transparent;
  border: 3px solid rgb(237, 234, 234);
  outline: none;
  color: black;
  font-size: 18px;
}

.HostedAppsDiv{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HostedAppsDiv a{
  justify-content: center;
}

.showhostedsites {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction:column ;
}

.site-card{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height:350px;
  background: rgba(180, 176, 176, 0.27);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(180, 176, 176, 0.3);
  padding: 20px 0;
  border-radius: 10px;
  flex-direction: column;
}

.site-card iframe{
  width: 70%;
  height: 100%;
  border: none;
}

.site-card a{
  background: black;
  color: white;
  padding: 10px 15px;
  font-size: 1.2rem;
  border-radius: 15px;
}
.site-card a:hover{
  background: rgb(40, 40, 40);
  color: white;
  padding: 10px 15px;
  font-size: 1.2rem;
  border-radius: 15px;
}

.wallet-details{
  width: 100%;
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* CSS */
.wallet-details button {
  margin-left: 20px;
  width: 100px;
  height: 50px;
  background-color: #FFFFFF;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

.wallet-details button:hover,
.wallet-details button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.wallet-details button:hover {
  background-color: black;
  color: white;
  transform: translateY(-1px);
}

.wallet-details button:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

#search2 {
  text-align: center;
  width: 30%;
  height: 20%;
  padding: 11px;
  border-radius: 15px;
  background: transparent;
  border: 2px solid rgb(237, 234, 234);
  outline: none;
  color: rgb(125, 125, 125);
  font-size: 20px;
}


/* upload page */
.fileuploader-main{
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.float{
  background: #c4bbbb;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid black;
}

.fileuploader-main > span{
  display: flex;
  align-items: center;
}
.fileuploader-left{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label > #fileinput{
  padding: 2px;
  /* background-color: rgb(28, 27, 27); */
  width: 50%;
}
input > #file-upload-button{
  padding: 2px;
  color: black;
}
.fileuploader-left > .label{
  border: 1px solid black;
  background: transparent;
  width: 500px;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.fileuploader-right{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileuploader-right > img{
  width:500px ;
}

.file_handler .file {
  width: 10px;
  margin-right: 50% !important;
}


#title{
  color: azure;
  font-size: 43px;
  font-style: italic;
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  font-family: Sundaycoffee;
  margin-left: 24%;
}

::placeholder { /* Microsoft Edge */
  color: rgb(125, 125, 125);
  /* font-family: Sundaycoffee; */
 }

 .white {
   color: white;
 }

 #ifrm {
  margin-left: 50%;
  margin-top: -23%;
 }

 @font-face {
  font-family: 'Sundaycoffee';
  src: local('Sundaycoffee'), url(./fonts/QabilFreeTrial-6Ydv1.otf) format('truetype');
}

@font-face {
  font-family: 'Sundaylove';
  src: local('Sundaylove'), url(./fonts/love-sunday-font/LoveSundayPersonalUse-6YE6q.ttf) format('truetype');
}

@font-face {
  font-family: 'Sundaystrong';
  src: local('Sundaystrong'), url(./fonts/be-strong-font/BeStrong-MVYAv.otf) format('truetype');
}

@font-face {
  font-family: 'ltlclut';
  src: local('ltlclut'), url(./fonts/little-clusters-font/LittleClustersRegDemo-jELnR.ttf) format('truetype');
}

@font-face {
  font-family: 'dingle';
  src: local('dingle'), url(./fonts/dingleberries-font/Dingleberries-Xyrg.ttf) format('truetype');
}



/* admin login */
.adminLogin-main{
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
}

.adminLogin-main h1{
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.adminLogin-main form{
  width: 350px;
  height: 200px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  gap: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.adminLogin-main form input{
  border-radius: 10px;
  padding: 10px;
  border: .5px solid black;
  
}

.adminLogin-main form button{
  border-radius: 10px;
  background: #000;
  color: white;
  padding: 10px 20px;
  border: none;
}

/* admin view */

.adminView-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  padding: 1rem;
}

.adminView-main h1{
  width: 100%;
  font-size: 30px;
  text-align: center;
}

.address-card{
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  border: .5px solid black;
}
.address-card:hover{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.address-card a{
  font-weight: 600;
  color: black;
}


.about_text{
  font-size: 40px;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
}

.about_{
  width: 100px;
}
.about_page{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about_page p{
  width: 60%;
  font-size: 1.3rem;
}

.about_page a{
  text-decoration: none;
}
.about_page a:hover{
  text-decoration: underline #000;
}

.det{
  border: solid 1px black;
  border-radius: 10px;
  width: 50%;
  margin-left: 200px;
  margin-top: 40px;
  padding-left: 20px;
}
